import Cookie from 'js.cookie';
// import lightGallery from 'lightgallery';
// import lgThumbnail from 'lightgallery/plugins/thumbnail'
// import lgZoom from 'lightgallery/plugins/zoom'
// import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    if (!Cookie.get('alert') && $('#modal').length || $('#modal').hasClass('no-cookie')) {
      $('#modal').show();
      Cookie.remove('alert');
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });
            
    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    //dropdown on click
    $('.banner nav li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
    });

    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });

    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
        $('.banner nav li.menu-item-has-children.open').removeClass('open');
      }
    });
    
    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });

    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if ($('.affiliates-carousel').length) {
      $('.affiliates-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: '.affiliates-panel .slider-nav',
        appendDots: '.affiliates-panel .slider-nav',
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: false,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

  //review us page
  $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    localStorage.setItem('activeTab', $(e.target).attr('href'));
  });
  // Get the stored active tab from localStorage
  var activeTab = localStorage.getItem('activeTab');
  // If there is no stored active tab or it doesn't exist, set the first tab as active
  if (!activeTab || !$('#review-tab a[href="' + activeTab + '"]').length) {
    $('#review-tab a[data-toggle="tab"]').first().tab('show');
  } else {
    // If there is a stored active tab, show it
    $('#review-tab a[href="' + activeTab + '"]').tab('show');
  }

  },
};
